import { Box, Chip, Stack } from '@mui/material';
import * as datefns from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppButton } from '~/components/AppButton';
import { AppHeader } from '~/components/AppHeader/AppHeader';
import { AppLabel } from '~/components/AppLabel/AppLabel';
import WeekCalendar from '~/components/WeekCalendar/WeekCalendar';
import { useAvailableTerminsQuery, useBarbersQuery } from '~/graphql/generated';
import { BookingContext } from './useBookingContext';

export const BarberAndTimeSelection = () => {
  const navigate = useNavigate();
  const context = useContext(BookingContext);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [weekDates, setWeekDates] = useState<Date[]>([]);
  const { data: barbersData } = useBarbersQuery({
    fetchPolicy: 'cache-first',
  });

  const { data: availableTermins } = useAvailableTerminsQuery({
    variables: {
      data: {
        barberId: context.barber?.id || 0,
        serviceId: context.service?.id || 0,
        dates: weekDates,
      },
    },
    skip: !weekDates.length || !context.service?.id || !context.barber?.id,
  });

  const selectedDayTimes = useMemo(
    () =>
      availableTermins?.availableTimeSlots.find((slot) => {
        const isSame = datefns.isSameDay(new Date(slot.date), selectedDate);

        if (!isSame) return false;

        return true;
      }),
    [availableTermins?.availableTimeSlots, selectedDate],
  );

  const disabledDates = useMemo(
    () =>
      weekDates.filter((date) => {
        const slot = availableTermins?.availableTimeSlots.find((slot) => datefns.isSameDay(new Date(slot.date), date));
        const noTerminsInDate = !slot || slot?.timeSlots.length === 0;
        return noTerminsInDate;
      }),
    [availableTermins?.availableTimeSlots, weekDates],
  );

  useEffect(() => {
    if (!context.service) {
      navigate(-1);
    }
  }, [context.service, navigate]);

  return (
    <>
      <AppHeader />

      <Stack gap={2} alignItems={'center'} justifyContent={'space-between'} flex={1} className="a">
        <Stack width={'100%'}>
          <AppLabel text="usluga" />
          <Box
            bgcolor={'secondary.main'}
            sx={{
              py: 2.5,
              px: 1.5,
            }}
          >
            <Chip label={context.service?.name} />
          </Box>

          <AppLabel text="odabir frizera" />
          <Stack px={1.5} py={2.5} overflow={'auto'} bgcolor={'secondary.main'} direction={'row'} gap={1.5}>
            {barbersData?.barbers.map((barber) => (
              <Chip
                key={`barber.id-${barber.id}`}
                variant={context.barber?.id === barber.id ? undefined : 'outlined'}
                label={barber.name}
                onClick={() => {
                  context.selectBarber({
                    id: barber.id,
                    name: barber.name,
                  });
                }}
              />
            ))}
          </Stack>

          {context.barber && (
            <>
              <AppLabel text="odabir datuma" />
              <Box py={2.5} px={1.5} bgcolor={'secondary.main'}>
                <WeekCalendar
                  onDayClickHandle={(day) => setSelectedDate(day)}
                  onWeekChangeHandle={setWeekDates}
                  disabledDates={disabledDates}
                />
              </Box>

              {selectedDate && (
                <>
                  <AppLabel text="vrijeme" />

                  <Stack px={1.5} py={2.5} overflow={'auto'} bgcolor={'secondary.main'} direction={'row'} gap={1.5}>
                    {selectedDayTimes?.timeSlots
                      .map((slot) => ({
                        start: new Date(slot.start),
                        end: new Date(slot.end),
                      }))
                      .map((time, i) => {
                        const isStartDateSelected =
                          context.selectedTime?.date &&
                          datefns.isSameDay(time.start, context.selectedTime?.date) &&
                          datefns.isSameHour(time.start, context.selectedTime?.start) &&
                          datefns.isSameMinute(time.start, context.selectedTime?.start);
                        const isEndDateSelected =
                          context.selectedTime?.date &&
                          datefns.isSameDay(time.end, context.selectedTime?.date) &&
                          datefns.isSameHour(time.end, context.selectedTime?.end) &&
                          datefns.isSameMinute(time.end, context.selectedTime?.end);

                        return (
                          <Chip
                            key={'time' + time.start.getTime() + i}
                            {...(isStartDateSelected && isEndDateSelected ? null : { variant: 'outlined' })}
                            label={`${datefns.format(time.start, 'HH:mm')}-${datefns.format(time.end, 'HH:mm')}`}
                            onClick={() => {
                              context.selectTime(selectedDate, time.start, time.end);
                            }}
                            sx={{
                              width: '120px',
                            }}
                          />
                        );
                      })}
                  </Stack>
                </>
              )}
            </>
          )}
        </Stack>
        <AppButton
          disabled={!context.barber || !context.selectedTime || !context.service}
          variant="contained"
          onClick={() => {
            navigate('/booking/review');
          }}
          sx={{
            width: '80%',
          }}
        >
          Pregled i potvrda
        </AppButton>
      </Stack>
    </>
  );
};
