import { Container, Skeleton, Snackbar, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppButton } from '~/components/AppButton';
import { AppLabel } from '~/components/AppLabel/AppLabel';
import { ServiceComponent } from '~/components/Service/Service';
import { useServicesQuery } from '~/graphql/generated';
import { BookingContext } from './useBookingContext';

export const ServiceSelection = () => {
  const context = useContext(BookingContext);
  const navigate = useNavigate();
  const { data, loading } = useServicesQuery({
    fetchPolicy: 'cache-first',
  });

  if (loading) {
    return (
      <Stack padding={2} gap={1}>
        <Skeleton variant="rounded" width={'100%'} height={100} />
      </Stack>
    );
  }

  return (
    <>
      <AppLabel text="odabir usluge" />

      <Container
        sx={
          context?.service
            ? {
                pb: 8,
              }
            : undefined
        }
      >
        <Stack gap={1}>
          {data?.services.map((service) => (
            <ServiceComponent
              key={`service.id-${service.id}`}
              service={service}
              onClick={() => context.selectService(service)}
            />
          ))}
        </Stack>
      </Container>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!!context.service}
        autoHideDuration={6000}
        message={
          <Stack>
            <Typography variant="caption" fontWeight={600} color="info.main">
              Odabrana usluga:
            </Typography>
            <Typography variant="body2" fontWeight={'500'} color={'white'}>
              {context?.service?.name}
            </Typography>
          </Stack>
        }
        action={
          <AppButton
            variant="contained"
            size="small"
            onClick={() => {
              if (context?.service?.bookable) {
                navigate('barber-time');
              } else {
                navigate('not-bookable', {
                  state: {
                    service: context?.service,
                  },
                });
              }
            }}
          >
            sljedeće
          </AppButton>
        }
      />
    </>
  );
};
