/* eslint-disable @typescript-eslint/no-explicit-any */
import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';
import { RouterProvider } from 'react-router-dom';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import Passwordless from 'supertokens-auth-react/recipe/passwordless';
import Session from 'supertokens-auth-react/recipe/session';
import { AppContainer } from './components/AppContainer';
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback';
import SnackbarCloseButton from './components/Snackbar/CloseButton';
import { router } from './router/routes';
import { theme } from './theme';

// To resolve:
// the browsers' back/forward cache doesn't work when IndexedDB has an open connection.
// https://github.com/firebase/firebase-js-sdk/issues/6167#issue-1209069250
const open = window.indexedDB.open.bind(window.indexedDB);
const dbs: any = [];

window.indexedDB.open = (...args) => {
  const request = open(...args);

  request.addEventListener('success', (event) => {
    if ((event.target as any)?.result) {
      const db = (event.target as any).result;
      dbs.push(db);
    }
  });

  return request;
};

window.addEventListener(
  'pagehide',
  () => {
    for (const db of dbs) {
      db.close();
    }
  },
  { capture: true },
);

const SuperTokensConfig = {
  appInfo: {
    appName: import.meta.env.VITE_SUPERTOKEN_APP_NAME || 'Barbershop',
    apiDomain: import.meta.env.VITE_SUPERTOKEN_API_DOMAIN || 'http://localhost:4001',
    websiteDomain: import.meta.env.VITE_SUPERTOKEN_WEBSITE_DOMAIN || 'http://localhost:5173',
    apiBasePath: import.meta.env.VITE_SUPERTOKEN_API_BASE_PATH || '/auth',
    websiteBasePath: import.meta.env.VITE_SUPERTOKEN_WEBSITE_BASE_PATH || '/authentication/options',
  },
  recipeList: [
    Passwordless.init({
      contactMethod: 'PHONE',
      signInUpFeature: {
        resendEmailOrSMSGapInSeconds: 60,
      },
    }),
    Session.init({
      tokenTransferMethod: 'header',
      sessionTokenFrontendDomain: import.meta.env.VITE_SUPERTOKEN_WEBSITE_DOMAIN || 'localhost:5173',
      sessionTokenBackendDomain: import.meta.env.VITE_SUPERTOKEN_API_DOMAIN || 'localhost:4001',
    }),
  ],
};
SuperTokens.init(SuperTokensConfig);

function App() {
  return (
    <SuperTokensWrapper>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AppContainer>
            <SnackbarProvider action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
              <RouterProvider router={router} />
            </SnackbarProvider>
          </AppContainer>
        </ErrorBoundary>
      </ThemeProvider>
    </SuperTokensWrapper>
  );
}

export default App;
